import React from 'react';

const ContactUs = () => {
    return (
        <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif', lineHeight: '1.6', color: 'black' }}>
            <h1 style={{ textAlign: 'center' }}>Contact Us</h1>
            <p style={{ textAlign: 'center', fontStyle: 'italic' }}>
                Last updated on 22-12-2024 13:34:27
            </p>
            <p>You may contact us using the information below:</p>
            <div>
                <p><strong>Merchant Legal Entity Name:</strong> VEERABAHU SANKARI</p>
                <p>
                    <strong>Registered Address:</strong> Plot 219, 17th Street, South Balabagyanagar, Junction,
                    Tirunelveli, Tamil Nadu, PIN: 627001
                </p>
                <p>
                    <strong>Operational Address:</strong> Plot 219, 17th Street, South Balabagyanagar,
                    Junction, Tirunelveli, Tamil Nadu, PIN: 627001
                </p>
                <p><strong>Telephone No:</strong> 9789908986</p>
                <p><strong>E-Mail ID:</strong> vsankari410@gmail.com</p>
            </div>
        </div>
    );
};

export default ContactUs;
