import {AppDispatch, AppThunk, RootState} from "../../common/redux/store";
import RegisterNavigation from "../RegisterNavigation";
import Profile from "../../common/model/Profile";
import {changeJWT, changeModifiedProfile, changeProfile, changeRefreshToken} from "./slice";
import AuthenticationService from "../../common/service/AuthenticationService";
import AuthenticationResponse from "../../common/model/AuthenticationReponse";
import ProfileService from "../../common/service/ProfileService";
import {batch} from "react-redux";
import AnalyticsService from "../../common/service/AnalyticsService";

export const fetchProfile = (): AppThunk => async (
    dispatch: AppDispatch,
    getState: () => RootState,
) => {
    const state: RootState = getState();
    const userId: string | null = AuthenticationService.getUserId(state);
    if (userId) {
        const profile: Profile = await ProfileService.fetchProfile();
        batch(() => {
            dispatch(changeProfile(profile));
            dispatch(changeModifiedProfile(profile));
        });
        RegisterNavigation.navigateToProfile()
    } else {
        RegisterNavigation.navigateToLogin();
    }
};

export const saveProfile = (): AppThunk => async (
    dispatch: AppDispatch,
    getState: () => RootState,
) => {
    const state: RootState = getState();
    let modifiedProfile: Profile = state.register.modifiedProfile;
    const userId: string | null = AuthenticationService.getUserId(state);
    if (userId) {
        try {
            modifiedProfile = Profile.setValidity(modifiedProfile);
            await ProfileService.saveProfile({
                ...modifiedProfile,
                id: userId,
            });
            await AnalyticsService.logEvent("profile_save", modifiedProfile.id)
            batch(() => {
                dispatch(changeProfile(modifiedProfile));
                dispatch(fetchProfile());
            });
            alert('Your profile has been saved successfully. You can close this website and wait for brides to contact you.');
        } catch (error: any) {
            await AnalyticsService.logEvent("profile_error", error.message);
            alert(error.message);
        }
    } else {
        RegisterNavigation.navigateToLogin();
    }
}

export const sendOTP = (token: string): AppThunk => async (
    dispatch: AppDispatch,
    getState: () => RootState,
) => {
    try {
        const state: RootState = getState()
        await AuthenticationService.sendOTP(state.register.mobileNumber, token)
        await AnalyticsService.logEvent("otp_sent", state.register.mobileNumber)
    } catch (error: any) {
        alert('Unable to send OTP: ' + error.message);
    }
}

export const attemptLogin = (): AppThunk => async (
    dispatch: AppDispatch,
    getState: () => RootState,
) => {
    try {
        const state: RootState = getState();
        const authResponse: AuthenticationResponse = await AuthenticationService.attemptLoginMobile(
            state.register.mobileNumber,
            state.register.code,
        );
        await AnalyticsService.logEvent("otp_verified", state.register.mobileNumber)
        dispatch(changeJWT(authResponse.jwt));
        dispatch(changeRefreshToken(authResponse.refreshToken));
        dispatch(fetchProfile())
    } catch (error: any) {
        alert('Unable to login: ' + error.message);
    }
}
