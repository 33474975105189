import Profile from "./Profile";

export default class ProfileValidator {

    static isValid(profile: Profile): string | undefined {
        const issues: string[] = [];

        // Calculate age
        const age: number = Math.abs(
            new Date(Date.now() - profile.dateOfBirth).getUTCFullYear() -
            1970
        );
        if (age < 21) {
            issues.push('You must be at least 21 years old to register');
        }
        if (
            profile.brothers !== undefined &&
            profile.brothersMarried !== undefined &&
            profile.brothers < profile.brothersMarried
        ) {
            issues.push("The total number of brothers can't be less than the number of married brothers");
        }
        if (
            profile.sisters !== undefined &&
            profile.sistersMarried !== undefined &&
            profile.sisters < profile.sistersMarried
        ) {
            issues.push("The total number of sisters can't be less than the number of married sisters");
        }
        if (!profile.name?.length) issues.push('Name is required');
        if (!profile.contact || profile.contact <= 1000000000) issues.push('Valid contact is required');
        if (!profile.caste) issues.push('Caste is required');
        if (!profile.religion) issues.push('Religion is required');
        if (!profile.dateOfBirth) issues.push('Date of birth is required');
        if (!profile.height) issues.push('Height is required');
        if (!profile.mother?.length) issues.push("Mother's name is required");
        if (!profile.father?.length) issues.push("Father's name is required");

        // Return the list of issues or undefined
        return issues.length > 0 ? issues.join('\n') : undefined;
    }
}
