import {ProfileStatus} from "./ProfileStatus";
import {Gender} from "./Gender";
import ProfileValidator from "./ProfileValidator";

export default class Profile {
    id: string = '';
    name: string = '';

    status: ProfileStatus = 'incomplete';
    dateOfBirth: number = Date.now();
    height: number = 160;
    star: string = 'N/A';
    rasi: string = 'N/A';
    lagnam: string = 'N/A';
    contact: number = 0;

    father: string = '';
    mother: string = '';
    brothers: number = 0;
    brothersMarried: number = 0;
    sisters: number = 0;
    sistersMarried: number = 0;
    religion: string = '';
    caste: string = '';

    contactAddress: string = '';
    educationalQualification: string = 'N/A';
    educationalSpecialization: string = '';
    employmentType: string = 'N/A';
    salary: number = 0;
    company: string = '';
    workLocation: string = '';

    preferences: string = '';

    static setValidity(profile: Profile): Profile {
        if (profile.status === 'inactive') {
            return profile;
        }
        const issuesWithProfile = ProfileValidator.isValid(profile);
        if (!issuesWithProfile) {
            return {...profile, status: 'active'};
        } else {
            throw Error(issuesWithProfile)
        }
    }
}
